<template>
  <el-container>
    <el-main>
      <el-row type="flex">
        <el-col id="rh-log">
          <router-view />
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "LogView",
};
</script>
